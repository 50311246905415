import axios from 'axios';

class UserstatsService {

  async list(filters, currentPage) {
    const response = await axios
      .get('api/user_stat/', {
        params: {
          timestamp_gte: filters.fromDate,
          timestamp_lte: filters.toDate,
          search: filters.search,
          page: currentPage,
        }
      });
    return response.data;
  }

  async export(filters) {
     const response = await axios
      .get('api/user_stat/export_user_stat/', {
        params: {
          timestamp_gte: filters.fromDate,
          timestamp_lte: filters.toDate,
          search: filters.search,
        },
        responseType: 'blob'
      });
    return response.data
  }

}


export default new UserstatsService();